import React from 'react'
import Layout from '../components/Layout'
import PageBanner from '../components/PageBanner'
import { Container, PagePadding, Grid, defaultTheme } from '../styles'
import { Link } from 'gatsby'
import styled from 'styled-components'

const ResourcesPage = () => {
    return (
        <Layout>
            <PageBanner title="Resources" subtitle="Los Angeles Personal Injury Lawyer" />
            <Container>
                <PagePadding>
                    <Grid twoCol>
                        <Block to="/reviews/">
                            Reviews
                        </Block>
                        <Block to="/personal-injury-blog/">
                            Blog
                        </Block>
                    </Grid>
                </PagePadding>
            </Container>
        </Layout>
    )
}

export default ResourcesPage

const Block = styled(Link)`
    padding: 104px 24px;
    background: ${defaultTheme.darkBlue};
    color: white;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.063em;
    text-align: center;
    transition: linear 0.35s;

    @media(hover: hover) and (pointer: fine) {
        &:hover {
            opacity: 0.9;
        }
    }
`;
